.description-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 20px;
}
.image {
    flex: 1;
    min-width: 300px;
    max-width: 100%;
    height: auto !important;
}
.text {
    flex: 1;
    min-width: 300px;
    max-width: 100%;
    text-align: justify;
}
.image img {
    width: 100%;
    height: auto !important;
    border-radius: 8px;
    opacity: 0.8;
}