.MuiStepLabel-label{
    color:rgb(116, 116, 116) !important;
}

.MuiStepLabel-label.Mui-active{
    color: #ffffff !important;
}

.crt-sender.form-control{
    width: 20rem;
border-color: #4d4b4b;
}

.card{
    border: .85px solid #4b4a4a !important;
}
.card .card-header{
    background-color: #31344e !important;
}